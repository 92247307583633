

























































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "@/components/account/rules";
import { mapActions, mapState } from "vuex";
import City from "@/models/City";
import stepFielfdMixin from "../stepFielfdMixin";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";

export default Vue.extend({
  components: { MaDatePicker },
  name: "medical-info-form",
  mixins: [stepFielfdMixin],
  data() {
    return {
      rules,
      stateCities: [],
      state: "",
      loadingCities: false,
      mailingCity: "",
      medicalInfoDateMenu: false,
      datesOfTRMenu: false,
      fields: {
        ss: "", // ss info
        dob: "", // dob info
        phone: "", // 17868027461
        // hfacilityName: "", // facility
        addres: "", // address info
        hAdnP: false, //
        poTR: false, //
        labReport: false, //
        EKGs: false, //
        radiologyR: false, //
        patologyRep: false, //
        others: "_", //
        printedName: "", //
        // date: "", // 2021-07-25T04:05:44.586Z
      },
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
  },
  watch: {
    state: function (stateId: number): void {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities: City[]) => {
        this.loadingCities = false;
        (this.stateCities as any) = cities;
      });
    },
    medicalInfoDateMenu(val: boolean) {
      try {
        val &&
          setTimeout(
            () => (this.$refs.medicalInfoDateDatepicker.activePicker = "YEAR")
          );
      } catch (error) {
        /*  */
      }
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    postProcedureDateDate(date: string): void {
      this.$refs.medicalInfoDateMenu.save(date);
    },
  },
});
